<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 256 256"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_152_24)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M199.64 5.89234C206.99 13.536 206.751 25.6904 199.108 33.04L98.3013 128L199.108 222.96C206.751 230.31 206.99 242.464 199.64 250.108C192.29 257.751 180.136 257.99 172.492 250.64L57.2923 141.84C53.5276 138.22 51.4 133.223 51.4 128C51.4 122.777 53.5276 117.78 57.2923 114.16L172.492 5.36003C180.136 -1.98961 192.29 -1.75128 199.64 5.89234Z"
        fill="#3B448C"
      />
    </g>
    <defs>
      <clipPath id="clip0_152_24">
        <rect
          width="256"
          height="256"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
